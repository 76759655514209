<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
// import AddItem from "@/own/components/fulfillment/aging/CreateItem.vue";
// import EditItem from "@/own/components/fulfillment/aging/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/inventory.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "Inventory",
  components: { DataTable },
  data: () => ({
    componentForEditing: null,
    componentForAdding: null,
    delete_url: `${process.env.VUE_APP_BASE_URL}/fulfillment/agings/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVENTTableFilters",
      getTableState: "getINVENTTableState",
      getTableData: "getINVENTTableData",
      getTableHeaders: "getINVENTTableHeaders",
      getTableProperties: "getINVENTTableProperties",
      getTableExportUrl: "getINVENTExportUrl",
      getTablePermissions: "getINVENTTablePermissions",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, {})
        .finally(() => {
          ApiService.post("/api/v2/guide-page/add", {
            entity: "inventory",
          });
        });
    },
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
